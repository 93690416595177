export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAW_QwpMSSCcIZdJbIRvheMWczqiKSN6AI',
    authDomain: 'jammcard-fe23d.firebaseapp.com',
    databaseURL: 'https://jammcard-fe23d.firebaseio.com',
    projectId: 'jammcard-fe23d',
    storageBucket: 'jammcard-fe23d.appspot.com',
    messagingSenderId: '414187709185',
    appId: '1:414187709185:web:d6d8aeb0242ccb9262f100',
    measurementId: 'G-5G5KJWTRZT',
  },
  log: true,
};

export const STRIPE = {
  PUBLISHABLE_KEY: 'pk_test_zuqqbdRAeGOFSC3imI9rRReh00bQaX4SLg',
  CLIENT_ID: 'ca_GAjweccMcvslCGS9YzfUuAFpo9OCkwxg',
  REDIRECT_BASE_URI: 'https://portal.dev-jammcard.com/seller-payout-method-confirm',
};

export const TEMP_STORAGE_API_KEY = '3fba345e-d9ee-4a4b-b410-9f4005ab2451';
